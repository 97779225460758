import { Injectable } from '@angular/core';
import { DPData, DPProductVariant, HeaderData } from './detail-page.data';
import { MarcheAPIService } from '../@core/api/marche-api.service';
import { BehaviorSubject, Observable, of } from 'rxjs';
import {
    ErrorWrapper,
    ResponseWrapper,
} from '../@shared/models/response-wrapper.model';
import { stringify } from 'querystring';
import { RouteExecutor } from '../@core/application-router/route.executor';
import { ApplicationRouterService } from '../@core/application-router/application-router.service';
import { FunctionModel } from '../@shared/models/function.model';
import { tap } from 'rxjs/operators';
import { plainToClassFromExist } from 'class-transformer';
import { DPReviewContentItem, RRDATA } from './rating-page.data';
import { ActivatedRoute } from '@angular/router';
import { AnalyticsService } from '../@core/analytics.service';

@Injectable({
    providedIn: 'root',
})
export class DetailPageService extends RouteExecutor {
    static readonly DP_DATA_API = '/dp/basic/v4';
    static readonly DP_DATA_VARIANT_API = '/dp/variants/v5';
    static readonly DP_DATA_HEADER_API = '/dp/header';
    static readonly DP_REVIEWS_API = '/dp/reviews/v2?page=';
    dpBasic: DPData = new DPData();
    dpHeader: Array<HeaderData> = [];
    dpVariants: Array<DPProductVariant> = [];
    filters: { [p: string]: string | string[] } = {};
    currentPage = -1;
    data: DPReviewContentItem[] = [];
    scrollPositionRestore: number | undefined;
    errors: ErrorWrapper[] = [];
    reviewPageError: ErrorWrapper[] | undefined = [];
    private counts = '';
    count$: BehaviorSubject<string> = new BehaviorSubject(this.counts);
    private selectedVariants: { [key: number]: number } = {};

    constructor(
        private marcheAPIService: MarcheAPIService,
        applicationRouterService: ApplicationRouterService,
        private analyticsService: AnalyticsService
    ) {
        super(applicationRouterService);

        // Retrieve selected variants from local storage if available
        const storedSelection = localStorage.getItem('selectedVariants');
        if (storedSelection) {
            this.selectedVariants = JSON.parse(storedSelection);
        }
    }
    setSelectedVariant(productIndex: number, variantIndex: number) {
        this.selectedVariants[productIndex] = variantIndex;
        // Save selected variants to local storage
        localStorage.setItem(
            'selectedVariants',
            JSON.stringify(this.selectedVariants)
        );
    }

    getSelectedVariant(): { [key: number]: number } {
        return this.selectedVariants;
    }
    // setProductHeader(data: HeaderData) {
    //   this.dpHeader = data;
    // }

    emptyFilters() {
        this.filters = {};
    }

    setFilters(data: { [p: string]: string | string[] }) {
        this.filters = data;
    }

    setScrollPosRestore(scroll: number) {
        this.scrollPositionRestore = scroll;
    }

    getScrollPos(filters: { [p: string]: string | string[] }): number {
        if (stringify(filters) === stringify(this.filters)) {
            return this.scrollPositionRestore as number;
        } else {
            return 0;
        }
    }

    getProductBasicDetail(filters: {
        [p: string]: string | string[];
    }): Observable<any> {
        if (stringify(filters) === stringify(this.filters)) {
            this.applicationRouterService.differNavApiCall.emit({
                navCall: true,
                pageId: 'dp',
            });
            if (this.errors?.length === 0) {
                return of({
                    success: true,
                    data: this.dpBasic,
                });
            } else {
                return of({
                    success: false,
                    errors: this.errors,
                });
            }
        } else {
            return this.marcheAPIService
                .getRequest<ResponseWrapper<DPData> | null>(
                    DetailPageService.DP_DATA_API,
                    filters
                )
                .pipe(
                    tap(res => {
                        if (res?.success) {
                            this.dpBasic = res.data;
                        } else {
                            plainToClassFromExist(this.errors, res?.errors);
                        }
                        this.applicationRouterService.differNavApiCall.emit({
                            navCall: true,
                            pageId: 'dp',
                        });
                        this.setFilters(filters);
                    })
                );
        }
    }

    getProductHeaderData(filters: {
        [p: string]: string | string[];
    }): Observable<any> {
        return this.marcheAPIService
            .getRequest<ResponseWrapper<Array<HeaderData>> | null>(
                DetailPageService.DP_DATA_HEADER_API,
                filters
            )
            .pipe(
                tap((res: any) => {
                    this.dpHeader = res.data;
                })
            );
    }

    setCounts(count: string) {
        this.count$.next(count);
    }
    getcounts(): BehaviorSubject<string> {
        return this.count$;
    }
    getProductVariantsData(filters: {
        [p: string]: string | string[];
    }): Observable<any> {
        if (stringify(filters) === stringify(this.filters)) {
            if (this.errors?.length === 0) {
                return of({
                    success: true,
                    data: this.dpVariants,
                });
            } else {
                return of({
                    success: false,
                    errors: this.errors,
                });
            }
        } else {
            return this.marcheAPIService
                .getRequest<ResponseWrapper<Array<DPProductVariant>> | null>(
                    DetailPageService.DP_DATA_VARIANT_API,
                    filters
                )
                .pipe(
                    tap((res: any) => {
                        this.dpVariants = res.data;
                    })
                );
        }
    }

    getReviewData(
        filters: { [p: string]: string | string[] },
        page: number,
        changeListingUrl?: boolean
    ): Observable<any> {
        if (
            stringify(filters) === stringify(this.filters) &&
            this.currentPage === page
        ) {
            this.applicationRouterService.differNavApiCall.emit({
                navCall: true,
                pageId: 'dp',
            });
            if (this.errors?.length === 0) {
                return of({
                    success: true,
                    data: this.data,
                });
            } else {
                return of({
                    success: false,
                    errors: this.errors,
                });
            }
        } else {
            if (this.analyticsService.isBot(window.navigator.userAgent)) {
                page = -1;
            }
            return this.marcheAPIService
                .getRequest<ResponseWrapper<RRDATA>>(
                    DetailPageService.DP_REVIEWS_API + page,
                    filters
                )
                .pipe(
                    tap(res => {
                        if (res?.success) {
                            if (page >= 0) {
                                this.applicationRouterService.differNavApiCall.emit(
                                    { navCall: true, pageId: 'dp' }
                                );
                            }
                            this.data.push(...res.data.contents);
                            this.currentPage = page;
                            this.errors = [];
                        } else {
                            this.data = [];
                            this.reviewPageError = res?.errors;
                        }
                    })
                );
        }
    }

    executeFunctionLink(
        id: string,
        query: { [p: string]: string | string[] },
        body?: object
    ) {
        this.marcheAPIService
            .postRequest<ResponseWrapper<FunctionModel> | null>(id, query)
            .subscribe((res: ResponseWrapper<FunctionModel> | null) => {
                this.updateState(res?.data);
            });
    }

    updateState(response: FunctionModel | undefined) {
        switch (response?.updateId) {
            case 'cta': {
                this.dpBasic.cta.link = response.state.link;
                this.dpBasic.cta.text = response.state.text;
                if (!!response.redirects) {
                    this.processLink(response.redirects[0]);
                }
                break;
            }
            case 'button': {
                if (!!this.dpBasic.carousel.wishlist) {
                    this.dpBasic.carousel.wishlist.icon = response.state.icon;
                    this.dpBasic.carousel.wishlist.link = response.state.link;
                }
                break;
            }
        }
        this.getProductBasicDetail(this.filters);
    }
}
